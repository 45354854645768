
html {
    height: 100%;
}

body {
    height: 100%;
    overflow: hidden;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#root > .MuiContainer-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: none;
    padding: 0;
}

#root > .customizer {
    pointer-events: none;
}

#root > .MuiContainer-root > .MuiGrid-root {
    flex: 1;
}

#root > .MuiContainer-root > .MuiGrid-container > .MuiGrid-item > div {
    pointer-events: initial;
}

#root .MuiPaper-root {
    background: rgb(18, 18, 18, 0.5);
    backdrop-filter: blur(10px);
}

#root .MuiDropzoneArea-root {
    background: rgba(0, 0, 0, 0.1);
    min-height: 15px;
}

#root .MuiDropzoneArea-icon {
    color: rgba(255, 255, 255, 0.8);
}

#root .MuiDropzoneArea-root .MuiDropzoneArea-text {
    font-size: 16px;
    padding: 4px;
}

#root .MuiCircularProgress-determinate {
    transition: none;
}

#root .MuiTooltip-tooltip {
    white-space: pre-line;
}

#root .file-drop-zone-compact .MuiDropzoneArea-text {
    font-size: 12px;
}

#root .file-drop-zone-compact .MuiDropzoneArea-icon {
    display: none;
}
